.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.photo {
  max-width: 90%;
  height: 90%;
  object-fit: contain;
  cursor: auto;
}

.modal svg {
  transform: scale(1.5);
  position: fixed;
  color: white;
  top: 15px;
  right: 15px;
  z-index: 10;
  cursor: pointer;
}

.modal svg:hover {
  transform: scale(2.0);
}