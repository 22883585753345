.gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  padding: 0 12px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.gallery .pics:hover {
  filter: opacity(0.8);
}



@media (max-width: 1600px) {
  .gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;  
  }
} 

@media (max-width: 991px) {
  .gallery {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;  
  }
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}
