@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

.bio-page {
  position: absolute;
  background-color: none;
  top: 0;
  background-image: url(../resources/angjan-196.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 10% 25%;
  height: 100vh;
  width: 100vw;
}

.bio {
  position: absolute;
  top: 20%;
  left: 50px;
  font-family: 'Courier Prime', monospace;
  font-size: 20px;
  margin: 20px auto;
  /* text-align: center; */
  width: 50vw;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 30px 30px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  line-height: 1.3;
}

/* @media (max-width: 1080px) {
  .bio {

  }
} */

@media (max-width: 1000px) {
  .bio {
    left: 5px;
    font-size: 18px;
    width: 85vw;
    padding-left: 10px;
  }
}

@media (max-height: 1080px) {
  .bio {
    top: 20vh;
    padding-top: 20px;
    padding-bottom: 0;
    width: 90vw;
    line-height: 1.25;
  }
}

@media (max-width: 540px) {
  .bio {
    top: 10vh;
    left: 0;
    padding-top: 20px;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    width: 93vw;
    margin: auto;
    line-height: 1.2;
    word-wrap: break-word;
  }
}
