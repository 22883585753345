.home-page {
  position: absolute;
  background-color: none;
  top: 0;
  background-image: url(../resources/AngieJhoLee_Headshot1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 10% 25%;
  height: 100vh;
  width: 100vw;
}

.contact-container {
  bottom: 0;
  height: 20vh;
  width: 100vw;
  display: flex;
  /* flex-direction: row; */
  margin: auto;
  justify-content: space-evenly;
  align-items: end;
  text-align: center;
  position: absolute;
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
}

.contact {
  margin-bottom: auto;
  margin-top: auto;
}


@media (max-width: 991px) {
  .contact-container {
    height: 25vh;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 540px) {
  .contact-container {
    height: 35vh;
  }
}