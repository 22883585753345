.container {
  width: 95vw;
  margin: auto;
}

.videos {
  padding-top: 20px;
}

@media (max-width: 540px) {
  .videos {
    padding-top: 0;
  }
}
